import React from 'react';
import {
    Container,
    NavbarText,
} from 'reactstrap';

const HomeContentText = (props) => {
    const {title, description} = props
    return(
        <Container fluid style={{ width: "100%" }}>
            <div className='content-text'>
                <NavbarText className='h2-title' style={{color: "#3a3847" }}>   
                    {title}
                </NavbarText>
            </div>
            <div className='content-text'>
                {/* <p className='p-1 text-center'>
                    {description}
                </p> */}
                {description}
            </div>
        </Container>
    );
};

export default HomeContentText;