import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import ah from "../../assets/img/ah.png";
import fin_management from "../../assets/img/financial_management.jpg";
import administration from "../../assets/img/administration.jpg";
import communication from "../../assets/img/communication.jpg";
import rtp from "../../assets/img/rtp.png";
import request_payment from "../../assets/img/request_payment.jpg";
import rtp_administration from "../../assets/img/rtp_administration.jpg";
import embedded_banking from "../../assets/img/embedded_banking.jpg";

const ControlledCarousel = () => {
  const carouselItems = [
    {
      src: ah,
      alt: "Account holder App",
      title: "App for Individual and families",
      text: "HealthMoni simplifies billing and payment for individual and families.",
    },
    {
      src: fin_management,
      alt: "Financial Management",
      title: "Financial Management",
      text: "HealthMoni simplifies billing and payment, keeping everything in one platform.",
    },
    {
      src: administration,
      alt: "Administration",
      title: "Administration",
      text: "Invoices and receipts are securely stored for future reference.",
    },
    {
      src: communication,
      alt: "Communication",
      title: "Communication",
      text: "Users can connect with service providers or funders in one place.",
    },
    {
      src: rtp,
      alt: "Request to Pay App",
      title: "Request to Pay App for Contractors and Carers",
      text: "Carers can submit payment requests with supporting documents through the Request to Pay App.",
    },
    {
      src: request_payment,
      alt: "Request For Payment",
      title: "Request For Payment",
      text: "Carers can submit payment requests with supporting documents.",
    },
    {
      src: rtp_administration,
      alt: "RtP Administration",
      title: "In-app Invoice and Timesheet Generation",
      text: "Carers can log hours worked and generate invoices or timesheets which are then shared for payment.",
    },
    {
      src: embedded_banking,
      alt: "Embedded Banking",
      title: "HealthMoni Embedded Banking",
      text: "Seamlessly manage healthcare financial accounts within partner apps.",
    },
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel-container">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index}>
            {/* Image Wrapper */}
            <div className="wrapper-container">
              <div className="image-container">
                <img className="carousel-image" src={item.src} alt={item.alt} />
              </div>

              {/* Text Below Image */}
              <div className="carousel-text">
                <h5>{item.title}</h5>
                <p>{item.text}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default ControlledCarousel;
