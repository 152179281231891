import React from 'react';
import {
    Container,
    NavbarText, Navbar,
    Row,
    Col,
} from 'reactstrap';
import ContentTop from '../common/ContentTop';
import HomeContentText from '../components/home/HomeContentText';
import FooterImage1 from '../assets/img/hm-logo-white-rgb-7@2x.png';
import FooterImage2 from '../assets/img/image-224@2x.png';
import hm_heirarchy from '../assets/img/hm_heirarchy.jpg';
import logo from "../assets/logo-no-background.png";
import Carousel from '../components/home/Carousel';

const Home = () => {
    return (
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Navbar className="ml-auto" expand="md" style={{ width: "100%" }}>
                <img src={logo} className="App-logo" alt="logo" />
            </Navbar>
             
            {/* <div className='title-container mt-4'>
                  <NavbarText className='title'>
                  {'HealthMoni Platform - Who is it for? What are the advantages? Who might be interested?'}
                  </NavbarText>
            </div> */}
            <HomeContentText title={'HealthMoni Platform'} />   
            <HomeContentText title={'Who is it for?'} />
            <HomeContentText title={'What are the advantages?'} />
            <HomeContentText title={'Who might be interested?'} /> 

            {/* <ContentTop image={homeTopImage} /> */}
            <div className='mt-4'></div>
            <HomeContentText title={'What is HealthMoni?'}
                description= {<p><b>HealthMoni</b> is a secure financial transaction platform designed specifically for the health and care sectors.
                It  manages payments and financial information, leveraging digital tools to provide better customer experience for individuals and families and more efficient settlement processes (shorter AR) for health and care sector organisations.</p>} />
            <HomeContentText title={'Who is it for?'}
                description={<p>HealthMoni has been built for <b>health and care service providers</b> and <b>funders</b>. It is  for health or care sector operator
                who wants to enhance financial functionality within their app. or provide a financial service by partnering and using the HealthMoni apps. 
                By integrating HealthMoni, sector participants can streamline payment processing and it offers real-time views of financial status and transactions. It improves insight, control and the overall customer experience.</p>} />
            <HomeContentText title={'What are the advantages?'}
                description = {<p><ul>
                    <li><b>Simplified Payments:</b> A user-friendly, low-cost settlement process tailored for the health and care industries.</li>
                    <li><b>Real-Time Financial Insights:</b> Partners and clients gain real-time access to payment requests, account balances and transaction histories, improving transparency.</li>
                    <li><b>Integration Flexibility:</b>
                        <ul>
                            <li>API integration, enabling partners to embed financial functionality directly into their own apps, providing full control over the user experience.</li>
                            <li>Embedded as a link or within the app for easy access to payments and financial services.</li>
                        </ul>
                    </li>
                    <li><b>Enhanced Customer Experience</b></li>
                    <li>Uses more transaction data for better tools across the stakeholder chain</li>
                    
                </ul></p>} />
            <HomeContentText title={'Who Might Be Interested?'}
                description={<p>HealthMoni is for  <b>health and care service providers  who want to embed financial solutions into their app,</b> to improve their own account receivables or to offer improved experience of payment and billing services to customer end users.</p>} />
            <HomeContentText title={'Financial functionality can be embedded in partner apps'}/>
            <Carousel />
            <HomeContentText title={'Why a new payments platform for healthcare?'}
                description={<><p>Self-pay for Health and Care is growing in the UK and globally. In Homecare, Residential Care, Hospital Care, Wellness support, Health Insurance an individual is increasingly involved in the payment and associated admin.</p>
 
                    <p>It is not well supported even in the more advanced payments environments such as the US. There are still only a few examples of innovative solutions around the world.</p>
                     
                    <p>“Consumer costs are growing, but adoption of digital and self-service payments remain slow in healthcare. How much longer can consumers wait for the tools to better manage their healthcare payments?” 
                    JP Morgan – Trends in Healthcare Payments annual report March 26, 2024 </p>
                    </>} />
            <HomeContentText title={'Evolving Environment'}
                description={<><p>Driven by an ageing population seeking more services and government policies providing less support, there is a large and growing self-pay health and care market in the UK.</p>
                    <p>Also due to government policies and digitisation from service providers, more responsibility is falling on the individual and family to perform financial management. Payments and Administration are often performed by a family member not the healthcare recipient. They can be onerous and emotional and to leave them so complicated is unnecessary.</p>
                     
                    <p>The sector is littered with software work arounds on traditional settlement platforms. <b>The need to use additional data supporting a clear payment transaction is not well supported.</b></p>
                     
                   <p>Today digital technology can provide better tools for all stakeholders.  Using new tools on new payment rails such as Open Banking allows providing a better experiences at lower cost.</p>
                    </>} />
            <HomeContentText title={'HealthMoni Vision and Mission'}
                description={<><p>Our vision is to be the leading provider of settlement services for Health and Care where self-payment is involved.</p> 
                    <p>The Platform integrates the ecosystem around a single financial account in an App.</p></>} />
            {/* <ContentTop image={hm_heirarchy} style={{ justifyContent: "center", width: "90%", height: "auto"}} /> */}
            <div style={{ justifyContent: "center", height: "600px", alignItems: "center"}}>
                <img className="carousel-image" src={hm_heirarchy} alt= "Heirarchy" />
            </div>
            <HomeContentText
                description = {<p><ol>
                    <li>A “banking, payments, e-money” App for individuals and families.</li>
                    <li>A Request to Pay App for contractors and carers.</li>
                    <li>API as a Service for business partners. Seamlessly integrate financial aspect in the partner app and customer experience.</li>
                    <li>Payment and settlement services.</li>
                    <li>Portals for business partners: For partner organizations to have transparency and efficient engagement.</li>
                    <li>HealthMoni can operate as a stand-alone app and is available in both the App Store and Google Play Store. Alternatively, functionality may be embedded in a partner’s app.</li>
                    </ol></p>} />
            <HomeContentText title={'Data Security'}
                description={<p>Data is stored securely, used for specific purposes, and made available to stakeholders on a permission basis. Data is stored securely, meeting GDPR and CyberEssentials Plus Data Security Standards</p>} />
             
            <Row className='footer-container p-1'>
                <Row className='align-items-center'>
                    <Col className="d-flex justify-content-start m-2">
                        <img
                            src={FooterImage1}
                            alt="hm_image"
                            style={{ width: "10em", height: "auto" }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <img
                            src={FooterImage2}
                            alt="footer_image"
                            style={{ width: "3em", height: "auto" }}
                        />
                    </Col>
                </Row>
                <hr className="footer-divider" />
                <Row className='m-2'>
                    <Col className='quicksand-normal-white'>
                    HealthMoni Limited is registered in England No. 11522723. Registered Office:  128 City Road, London, United Kingdom, EC1V 2NX.
                    </Col>
                </Row>
                <Row className='m-1'>
                    <Col className='quicksand-normal-white footer-padding'>
                    For more detail contact at howard@healthmoni.com
                    </Col>
                </Row>
            </Row>  
        </Container>
    )
}

export default Home;